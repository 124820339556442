
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    UploadContentDialog: () => import('@/components/dialogs/UploadContentDialog.vue')
  }
})
export default class Index extends Vue {


}
